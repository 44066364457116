.App {
  text-align: center;
}

.select-container {
  display: flex;
  flex-direction: row;
  border: 1px solid #ccc;
  font-size: 1.2em;
  padding: 10px 0;
  cursor: pointer;
}
.select-value {
  justify-content: flex-start;
  flex-grow: 1;
  padding-left: 10px;
  text-align: left;
}
.select-toggle {
  justify-content: flex-end;
  margin-left: auto;
  text-align: right;
  padding: 0 10px;
}
.select-items {
  cursor: pointer;
}
.select ul {
  margin: 0;
  padding: 0;
}
.select ul li {
  padding: 10px;
  text-decoration: none;
  list-style-type: none;
  border-bottom: 1px solid #ccc;
}

.date-select {
  display: flex;
  border: 1px solid #ccc;
  font-size: 1.2em;
}
.date-select-prev {
  justify-content: flex-start;
  margin-right: auto;
  text-align: left;
  cursor: pointer;
  padding: 10px;
}
.date-selected {
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  padding: 8px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.date-select-next {
  justify-content: flex-end;
  margin-left: auto;
  text-align: right;
  cursor: pointer;
  padding: 10px;
}

.date-selector .react-calendar {
  width: 100%;
}

.date-selector .react-calendar__tile--active {
  background: #444;
  color: white;
}
.date-selector .react-calendar__tile--active:enabled:hover {
  background: #444;
  color: white;
}

.date-selector .react-calendar__tile--now {
  background: #006edc;
  color: white;
}
.date-selector .react-calendar__tile--now:enabled:hover {
  background: #006edc;
  color: white;
}

.workout .select-item {
  text-align: left;
  padding: 5px 10px;
}

.workout .select-container {
  background-color: #444;
}

.workout .select-value {
  font-weight: bold;
  color: #fff;
}

.exercise .select-container {
  background-color: #fff;
}
.exercise .select-value {
  font-weight: bold;
  color: #000;
}

.workout .select ul {
  margin: 0;
}

.workout .select-container {
  border: none;
}

.exercise .select-container {
  border: none;
  font-size: 1em;
}

.player-wrapper {
  position: relative;
  padding-top: 36.25%; /* 720 / 1280 = 0.5625 */
}

.workout .select-items {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

.workout .rest {
  padding: 20px;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  font-size: 1em;
}

.select-value.title {
  text-align: center;
}

.dot {
  height: 6px;
  width: 6px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 4px auto;
}

@media only screen and (min-width: 600px) {
  body {
    width: 600px;
    margin: 0 auto;
  }
}
